import * as React from "react";
import { graphql, HeadProps, PageProps } from "gatsby";

import TemplateHead from "../components/template-head";
import Layout from "../components/layout";
import TrustpilotCarousel from "../components/trustpilot/trustpilot-carousel";
import Hero from "../components/hero";
import ArticleContent from "../components/article-content";
import ArticlesGridRelative from "../components/articles-relative";
import Section from "../components/section";
import Accordion from "../components/accordion";

type DataProps = {
  strapiArticle: any;
  strapiAdvisor: any;
};

export default function ArticlePage({
  data: { strapiArticle, strapiAdvisor },
}: PageProps<DataProps>) {
  const article = strapiArticle;
  const advisor = strapiAdvisor;

  return (
    <Layout as="article">
      <Hero
        heroGuidanceLink={article.urlPart}
        heroCategoryLink={article.parentCategorySlug}
        heroCategoryTitle={article.parentCategory}
        articleTitle={article.title}
        heroButton={"Back to articles"}
        imageGlow={"/images/glow.png"}
        sectionClassName="hero-section hero-section--post pl-4 pr-4"
        contentClassName="hero-content hero-content--post"
        bgImageClassName="hero-image hero-image--post"
      >
        <ArticleContent
          mobile={false}
          medium={true}
          articleContent={article}
          articleAdvisor={advisor}
        />
      </Hero>
      <ArticlesGridRelative
        category={article.article_categories[0].urlPart}
        currentArticle={article.urlPart}
      />
      {article.article_faq_data && article.article_faq_data.length > 0 && (
        <Section
          sectionClass={
            "pt-3 pb-2 pb-lg-5 pl-4 pr-4 section-container bg-white"
          }
        >
          <div className="block-wrapper pt-lg-5 mt-lg-5">
            <h2 className="mb-3 mb-lg-5">Frequently asked questions</h2>
            <Accordion items={article.article_faq_data} />
          </div>
        </Section>
      )}
      <TrustpilotCarousel />
    </Layout>
  );
}

export function Head({ data: { strapiArticle } }: HeadProps<DataProps>) {
  const article = strapiArticle;
  return (
    <TemplateHead
      title={article.title}
      shortlink={"https://fenixfuneral.co.uk/guidance/" + article.urlPart}
      alternate={"https://fenixfuneral.co.uk/guidance/" + article.urlPart}
      canonical={"https://fenixfuneral.co.uk/guidance/" + article.urlPart}
      description={
        article.seo[0]?.metaDescription
          ? article.seo[0].metaDescription
          : "Arrange beautiful funeral from £1095. Funeral director with ✓ Top customer ratings 4.8 out of 5. ✓ Excellent Service ✓ Simple planning."
      }
      og={{
        url: "https://fenixfuneral.co.uk/guidance/" + article.urlPart + "/",
        title: article.seo[0]?.metaTitle
          ? article.seo[0]?.metaTitle
          : article.title,
        type: "article",
        description: article.seo[0]?.metaDescription
          ? article.seo[0]?.metaDescription
          : article.title,
        image: article.coverImage.localFile?.url
          ? article.coverImage.localFile?.url
          : "https://fenixfuneral.co.uk/images/Logo-Dark.svg",
      }}
      ldjson={
        `{
                        "@context": "https://schema.org",
                        "@id": "https://fenixfuneral.co.uk/#webpage",
                        "url": "https://fenixfuneral.co.uk/guidance/` +
        article.urlPart +
        `/",
                        "@type": "WebPage",
                          "name": "${
                            article.seo[0]?.metaTitle
                              ? article.seo[0]?.metaTitle
                              : article.title
                          }"
      }`
      }
      ldjsonmore={
        `{
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        "headline": "${
          article.seo[0]?.metaTitle ? article.seo[0]?.metaTitle : article.title
        }",
        "description": "${
          article.seo[0]?.metaDescription
            ? article.seo[0]?.metaDescription
            : "Arrange beautiful funeral from £1095. Funeral director with ✓ Top customer ratings 4.8 out of 5. ✓ Excellent Service ✓ Simple planning."
        }",
        "image": "${
          article.coverImage.localFile?.url
            ? article.coverImage.localFile?.url
            : "/images/Logo-Dark.svg"
        }",
        "author": {
            "@type": "Person",
            "name": "${article.authorFullName ? article.authorFullName : ""}"
        },
        "publisher": {
            "@type": "Organization",
            "name": "Fenix Funeral LTD",
            "logo": {
                "@type": "ImageObject",
                "url": "/images/Logo-Dark.svg"
            }
        },
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://fenixfuneral.co.uk/guidance/` +
        article.urlPart +
        `/"
        },
        "url": "${
          article.seo[0]?.canonicalURL
            ? article.seo[0]?.canonicalURL
            : "https://fenixfuneral.co.uk/guidance/" + article.urlPart
        }" ,
        "datePublished": "${article.publishedAt ? article.publishedAt : ""}",
        "dateCreated": "${article.publishedAt ? article.publishedAt : ""}",
        "dateModified": "${
          article.updatedAt ? article.updatedAt : article.publishedAt
        }"
        }`
      }
      ldjsonmedia={`{
        "@context": "https://schema.org",
        "@type": "MediaObject",
        "name": "${
          article.seo[0]?.metaTitle ? article.seo[0]?.metaTitle : article.title
        }",
        "description": "${
          article.seo[0]?.metaDescription
            ? article.seo[0]?.metaDescription
            : "Arrange beautiful funeral from £1095. Funeral director with ✓ Top customer ratings 4.8 out of 5. ✓ Excellent Service ✓ Simple planning."
        }",
        "image": "${
          article.coverImage.localFile?.url
            ? article.coverImage.localFile?.url
            : "/images/Logo-Dark.svg"
        }",
        "aggregateRating": {
            "@type":"AggregateRating",
            "bestRating": "5",
            "ratingValue": "4.8",
            "ratingCount": "152"
        }
        }`}
    />
  );
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiArticle(urlPart: { eq: $slug }) {
      id
      strapi_id
      viewCounter
      authorFullName
      publishedAt(formatString: "YYYY-MM-DD")
      updatedAt(formatString: "YYYY-MM-DD")
      urlPart
      title
      article_categories {
        urlPart
      }
      parentCategory
      parentCategorySlug
      displaySafeFooter
      shortContent {
        data {
          shortContent
        }
      }
      article_faq_data {
        question
        answer
      }
      seo {
        canonicalURL
        metaDescription
        metaTitle
      }
      fullContent {
        data {
          fullContent
        }
      }
      coverImage {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData(
              width: 900
              height: 360
              outputPixelDensities: 1
              transformOptions: { cropFocus: CENTER, fit: COVER }
            )
          }
        }
      }
    }
    strapiAdvisor(url: { eq: "melonie-johansson" }) {
      id
      title
      url
      fullname
      description {
        data {
          description
        }
      }
      photo2x {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 144
              height: 144
              placeholder: NONE
              outputPixelDensities: 1
            )
          }
        }
      }
    }
  }
`;
