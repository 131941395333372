import * as React from "react";
import { getImage, withArtDirection } from "gatsby-plugin-image";
import { graphql, HeadProps, PageProps } from "gatsby";
import Cookies from "universal-cookie";
import loadable from "@loadable/component";

import { useCityList } from "../hooks/use-city-list";
import Layout from "../components/layout";
import Hero from "../components/hero";
import HeroContentHeading from "../components/hero-content-heading";
import Block8Text4Image from "../components/blocks/block-8text-4image";
import Block8Right from "../components/blocks/block-8right";
import Section from "../components/section";
import TrustpilotCarousel from "../components/trustpilot/trustpilot-carousel";
import MapSingle from "../components/map/single-fixed";
import ArticlesGridShort from "../components/articles-grid-short";
import AdvisorRandom from "../components/advisor-random";
import LocationsTabs from "../components/tab/locations-tabs";
import FuneralPackageGrid from "../components/funeral-package-grid";
import Popout from "../components/city-select";
import TemplateHead from "../components/template-head";
import FuneralDirectorsNearest from "../components/funeral-directors-nearby";

const BlockMap = loadable(() => import("../components/blocks/block-map"));

import "../styles/page-city.scss";

type DataProps = {
  strapiCity: any;
  advisorBg: any;
  funeralHomesBg: any;
  locationsBg: any;
  cemeteries: any;
  churches: any;
  defaultHeroDesktopBg: any;
  defaultHeroTabletBg: any;
  defaultHeroMobileBg: any;
};

const cookies = new Cookies();

export default function CityPage({
  data: {
    strapiCity,
    churches,
    cemeteries,
    advisorBg,
    locationsBg,
    funeralHomesBg,
    defaultHeroDesktopBg,
    defaultHeroTabletBg,
    defaultHeroMobileBg,
  },
}: PageProps<DataProps>) {
  const city = strapiCity;

  const cookieCity = cookies.get("fenix4city");
  if (cookieCity !== city.cityUrl) {
    cookies.remove("fenix4city", { path: "/" });
    cookies.remove("fenix4hub", { path: "/" });
    let d = new Date();
    d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
    let expiresTime = d;
    cookies.set("fenix4city", city.cityUrl, {
      expires: expiresTime,
      path: "/",
    });
    cookies.set("fenix4label", city.title, {
      expires: expiresTime,
      path: "/",
    });
    cookies.set("fenix4hub", city.hub.urlPart, {
      expires: expiresTime,
      path: "/",
    });
  }
  const imagesHero = withArtDirection(
    getImage(
      city.image?.hero?.localFile
        ? city.image?.hero?.localFile
        : defaultHeroDesktopBg.edges[0].node.localFile
    ),
    [
      {
        media: "(max-width: 719px)",
        image: getImage(
          city.image?.small2x?.localFile
            ? city.image?.small2x?.localFile
            : defaultHeroTabletBg.edges[0].node.localFile
        ),
      },
      {
        media: "(max-width: 1199px)",
        image: getImage(
          city.image?.middle?.localFile
            ? city.image?.middle?.localFile
            : defaultHeroMobileBg.edges[0].node.localFile
        ),
      },
    ]
  );

  const pageTextMap = (
    <div>
      <p>
        We are experts at supporting families to make funeral arrangements, as
        well as other legal and practical arrangements, online and over the
        telephone.
      </p>
      <p>
        However, many of our customers value meeting in person. We can come to
        you, or meet at one of our private meeting rooms.
      </p>
      <p>
        Please note that{" "}
        <em>
          <b>you must always book an appointment in advance</b>
        </em>
        . You can easily book an appointment by calling us or sending an e-mail.
      </p>
    </div>
  );

  const pageTextPlan = (
    <div dangerouslySetInnerHTML={{ __html: city.text?.textPlan }}></div>
  );

  return (
    <Layout as="city">
      <Hero
        imageOptimized={imagesHero}
        altImages={city.seo?.metaTitle}
        sectionClassName="hero-section hero-section--city pl-4 pr-4"
        bgImageClassName="hero-image hero-image--city"
        attribute={city.image?.hero?.name}
        contentClassName="hero-content hero-content--city"
        breadcrumb={[
          { name: "Fenix Home", slug: "/" },
          {
            name: city.heading.main
              ? city.heading.main
              : "Fenix Funeral Directors in " + city.title,
            slug: "/funeral-directors/" + city.cityUrl + "/",
          },
        ]}
      >
        <HeroContentHeading
          headingTitle={
            city.heading.main
              ? city.heading.main
              : "Fenix Funeral Directors in " + city.title
          }
          headingContent={"Trusted and affordable – from £ 1095"}
          headingLocation={
            city.office && city.office?.fullAddress
              ? city.office?.fullAddress + ", " + city.office?.postcode
              : ""
          }
          advisorData={city.advisorList[0]?.advisorCity}
        />
        <a name="about-fenix"></a>
        <Block8Text4Image
          h2={
            city.text?.headingWelcome
              ? city.text?.headingWelcome
              : "Our funeral homes in " + city.title
          }
          text={
            city.text?.textWelcome +
            "<p>" +
            city.text?.textSpecialService +
            "</p>" +
            "<p>When you contact us at Fenix Funeral in <strong>" +
            city.title +
            "</strong>, you never have to worry about being treated like a “customer”. For us, you are a fellow human being who needs support and help in a difficult time. You always get a personal adviser who is by your side through out the process.</p>"
          }
          bgImage={"/images/fenix-funeral-homes-in-city@2x.jpg"}
          rightImageBg={funeralHomesBg?.edges}
          linkText="Read more"
        />
      </Hero>
      <a id="local-adviser"></a>
      <Section
        sectionClass={
          "bg-deepblue pl-4 pr-4 section-container section-container--plan pt-5"
        }
      >
        <Block8Right h2={city.text?.headingPlan} text={pageTextPlan} />
        <img
          src="/images/stripe_1.svg"
          alt="Fenix Funeral Directors"
          className="stripe stripe--1 stripe--city d-none d-md-block"
        />
      </Section>
      <Section
        sectionClass={"pl-4 pr-4 section-container section-container--map"}
      >
        <a name="local-office"></a>
        <BlockMap
          rowClass="row no-gutters flex-column-reverse flex-lg-row-reverse"
          col1Class="col-12 col-lg-4 col-bottom-right bg-white pl-3 pl-md-5 pr-3 pr-md-5 pt-4 pt-md-5 pb-4 pb-md-5"
          col2Class="col-12 col-lg-8 col-top-left"
          h2={"Meeting Rooms — by appointments only"}
          text={pageTextMap}
          map={
            <MapSingle
              key={`map-single-key`}
              lat={city.geo?.latitude}
              lng={city.geo?.longitude}
              city={city.title}
              heading={city.heading?.main}
              company={city.office?.corporateChain}
              address={city.office?.fullAddress}
              postcode={city.office?.postcode}
            />
          }
          citySelect={
            <Popout cities={useCityList()} id={"topbarSelectCityPost"} />
          }
        />
      </Section>
      <Section sectionClass={"section-container package-section pl-4 pr-4"}>
        <FuneralPackageGrid hubSlug={city.hub.urlPart} />
      </Section>
      <a name="crematoriums"></a>
      <LocationsTabs
        locationsImageBg={locationsBg?.edges}
        city={city}
        cemeteries={cemeteries}
        churches={churches}
        lat={city.geo?.latitude}
        lng={city.geo?.longitude}
      />
      <a name="guide"></a>
      <ArticlesGridShort
        gridClass={"articles-section articles-section--city pr-4 pl-4"}
      />
      <AdvisorRandom
        advisorImageBg={advisorBg?.edges}
        advisorData={city.advisorList[0]?.advisorCity}
        sectionClassName="advisor-book advisor-book--section advisor-book--city pr-4 pl-4 "
      />
      <a name="local-adviser"></a>
      <FuneralDirectorsNearest
        cityId={city?.strapi_id}
        lat={city.geo?.latitude}
        lng={city.geo?.longitude}
      />
      <TrustpilotCarousel />
    </Layout>
  );
}

export function Head({
  data: { strapiCity, defaultHeroDesktopBg },
}: HeadProps<DataProps>) {
  const city = strapiCity;
  return (
    <TemplateHead
      title={city.seo.metaTitle}
      shortlink={city.seo.canonicalURL}
      alternate={city.seo.canonicalURL}
      canonical={city.seo.canonicalURL}
      description={city.seo.metaDescription}
      og={{
        url: city.seo.canonicalURL,
        title: city.seo.metaTitle,
        type: "business.business",
        description: city.seo?.metaDescription,
        image: city.image?.hero?.url
          ? city.image?.hero?.url
          : defaultHeroDesktopBg.edges[0].node.url,
        imageWidth: 600,
        imageHeight: 315,
      }}
      business={{
        contact_data: {
          street_address: city.office?.fullAddress
            ? city.office.fullAddress
            : "",
          locality: city.title,
          region: city.county,
          postal_code: city.office?.postcode ? city.office?.postcode : "",
          country_name: "United Kingdom",
        },
      }}
      ldjson={`{
          "@context": "https://schema.org",
          "@id": "https://fenixfuneral.co.uk/#webpage",
          "url": "${city.seo.canonicalURL}",
          "@type": "LocalBusiness",
          "name": "Fenix Funeral Directors in ${city.title}",
          "priceRange": "£1095 - £2280",
          "telephone": "0808 164 9040",
          "image":"${
            city.image?.hero?.url
              ? city.image?.hero?.url
              : defaultHeroDesktopBg.edges[0].node.url
          }",
          "aggregateRating":{
              "@type":"AggregateRating",
              "ratingValue":"4.8",
              "reviewCount":"152"
          },
          "address":{
              "@type":"PostalAddress",
              "streetAddress":"${
                city.office?.fullAddress ? city.office?.fullAddress : ""
              }",
              "addressLocality":"${city.title}",
              "addressRegion":"${city.hub?.title}",
              "postalCode":"${
                city.office?.postcode ? city.office?.postcode : ""
              }",
              "addressCountry":{
                  "@type":"Country",
                  "name":"United Kingdom"
              }
          }
          }`}
      ldjsonbreadcrumb={`{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement":
          [
            {
            "@type": "ListItem",
            "position": "1",
            "name": "Fenix Home","item": "https://fenixfuneral.co.uk/"
            },
            {
            "@type": "ListItem",
            "position": "2",
            "name": "Fenix Funeral Directors in ${city.title}","item": "${city.seo.canonicalURL}"
            }
          ]
          }`}
      ldjsonfaq={`{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": 
          [
            {
              "@type": "Question",
              "name": "Can we have a viewing or visitation before the funeral service?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, you are welcome to view your loved one at chapels of rest at select locations, we only ask that you schedule an appointment with your appointed Funeral Advisor. As viewing is often a personal choice for each family and not something that is required, we do not include this with the main package which only includes essential elements of a funeral."
              }
            },{
              "@type": "Question",
              "name": "What is the process for planning a funeral?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Once Fenix receives your enquiry, you will be placed with a personal Funeral Advisor who is responsible for completing all necessary paperwork and liaising with local authorities as required. Your expert Funeral Advisor will also help you decide on the right elements (coffins, flower tributes, and more) for your funeral."
              }
            },{
              "@type": "Question",
              "name": "What are the available options for caskets and urns?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Our casket and urn selection can be viewed on our website. Please note that our website showcases only the most popular of our product selection, we offer many more options, if there is something in particular you have in mind discuss it with your Funeral Advisor."
              }
            },{
              "@type": "Question",
              "name": "Can you coordinate with our chosen religious leader or celebrant for the service?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Can you coordinate with our chosen religious leader or celebrant for the service?"
              }
            },{
              "@type": "Question",
              "name": "Can you recommend local florists for funeral flowers and arrangements?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We work with florists all over the UK, we can even create custom funeral tributes. For more information on our selection of floral arrangements and custom tributes, speak to your Funeral Advisor."
              }
            },{
              "@type": "Question",
              "name": "Can you assist with arranging a reception or gathering after the funeral service?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, for a small fee we can help you arrange wakes, memorials, and/or receptions before or after the funeral service."
              }
            },{
              "@type": "Question",
              "name": "When my loved one is brought into care, where will they be resting?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "When we bring your loved one into care, they will be resting in one of our care facilities. These facilities are specially designed to be temperature and moisture controlled."
              }
            }
          ]
          }`}
    />
  );
}

export const pageQuery = graphql`
  fragment imageDataHero on STRAPI__MEDIA {
    url
    localFile {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, outputPixelDensities: 1)
      }
    }
  }
  fragment imageDataBg on FileConnection {
    edges {
      node {
        name
        childImageSharp {
          gatsbyImageData(placeholder: NONE, outputPixelDensities: 1)
        }
      }
    }
  }
  fragment locationsData on STRAPI_LOCATIONConnection {
    nodes {
      strapi_id
      title
      slug
      type {
        slug
        title
      }
      hub {
        title
        urlPart
      }
      city {
        title
        cityUrl
      }
      images {
        awsThumbnail
        awsImage
      }
      geo {
        longitude
        latitude
      }
    }
  }
  query ($slug: String, $hubSlug: String) {
    strapiCity(cityUrl: { eq: $slug }) {
      id
      strapi_id
      title
      cityUrl
      isHub
      office {
        corporateChain
        fullAddress
        postcode
      }
      geo {
        latitude
        longitude
      }
      seo {
        metaDescription
        metaTitle
        canonicalURL
      }
      textSeo {
        whyFenixLocalTitle
        whyFenixLocalText
        uspTitle3
        uspTitle2
        uspTitle1
        uspText3
        uspText2
        uspText1
        titleTabCrematorium
        textTabCrematorium
        titleTabCemetery
        textTabCemetery
        titleTabChurch
        textTabChurch
        textWelcome
        advisorsSectionTitle
        articlesText
        articlesTitle
        cityAdvisorsText
        cityAdvisorsTitle
        cityBespokeBottom
        cityBurialBottom
        cityBespokeTop
        cityBurialTop
        cityCremationBottom
        cityCremationTop
        cityCrematoriumBottom
        cityCrematoriumTop
        cityPackagesText
        cityPackagesTitle
        headingWelcome
      }
      text {
        headingPlan
        textPlan
        textSpecialService
        textWelcome
        headingWelcome
        textTabChurch
        textTabCemetery
      }
      heading {
        main
      }
      hub {
        id
        title
        urlPart
      }
      hasServicePages
      image {
        middle {
          ...imageDataHero
        }
        hero {
          alternativeText
          name
          url
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                outputPixelDensities: 1
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        small2x {
          ...imageDataHero
        }
      }
      advisorList {
        advisorCity {
          url
          fullname
          description {
            data {
              description
            }
          }
          photo2x {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, outputPixelDensities: 1)
              }
            }
          }
        }
      }
    }
    defaultHeroDesktopBg: allStrapiMedia(
      filter: {
        url: {
          eq: "https://nextpwcms.s3.eu-north-1.amazonaws.com/city_placeholder_f4f23a349f.jpg"
        }
      }
    ) {
      edges {
        node {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, outputPixelDensities: 1)
            }
          }
        }
      }
    }
    defaultHeroTabletBg: allStrapiMedia(
      filter: {
        url: {
          eq: "https://nextpwcms.s3.eu-north-1.amazonaws.com/city_placeholder_3b9130ae5a.jpg"
        }
      }
    ) {
      edges {
        node {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, outputPixelDensities: 1)
            }
          }
        }
      }
    }
    defaultHeroMobileBg: allStrapiMedia(
      filter: {
        url: {
          eq: "https://nextpwcms.s3.eu-north-1.amazonaws.com/city_placeholder_2x_4fc0811750.jpg"
        }
      }
    ) {
      edges {
        node {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, outputPixelDensities: 1)
            }
          }
        }
      }
    }
    advisorBg: allFile(filter: { name: { in: ["AdvisorBackgroundImage"] } }) {
      ...imageDataBg
    }
    funeralHomesBg: allFile(
      filter: { name: { in: ["fenix-funeral-homes-in-city@2x"] } }
    ) {
      ...imageDataBg
    }
    locationsBg: allFile(
      filter: { name: { in: ["BottomBackgroundImage-Web"] } }
    ) {
      ...imageDataBg
    }
    churches: allStrapiLocation(
      filter: {
        type: { slug: { in: ["churches"] } }
        hub: { urlPart: { in: [$hubSlug] } }
      }
      sort: { fields: title, order: ASC }
    ) {
      ...locationsData
    }
    cemeteries: allStrapiLocation(
      filter: {
        type: { slug: { in: ["cemeteries"] } }
        hub: { urlPart: { eq: $hubSlug } }
      }
      sort: { fields: title, order: ASC }
    ) {
      ...locationsData
    }
  }
`;
