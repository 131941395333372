import * as React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "../components/layout";
import Hero from "../components/hero";
import TrustpilotCarousel from "../components/trustpilot/trustpilot-carousel";
import LocationsFilter from "../components/locations-filter";
import TemplateHead from "../components/template-head";

type DataProps = {
  allLocations: any;
  slug: any;
};

export default function Locations({
  location,
  pageContext,
  data: { allLocations },
}: PageProps<DataProps>) {
  const locations = allLocations.nodes; // Your array with 1000 objects

  return (
    <Layout>
      <Hero
        sectionClassName="hero-section hero-section--locations pl-4 pr-4"
        bgImageClassName="hero-image hero-image--blog"
        contentClassName="hero-content hero-content--blog"
        altImages="Direct Cremation Fenix Funeral Directors"
        imageGlow={"/images/glow.png"}
        breadcrumb={[
          { name: "Fenix Home", slug: "/" },
          { name: "Locations in UK", slug: "/locations/" },
        ]}
      ></Hero>
      <LocationsFilter
        locationsData={locations}
        contextData={pageContext}
        prevLocation={location.state ? location.state : null}
      />
      <TrustpilotCarousel />
    </Layout>
  );
}

export function Head() {
  return (
    <TemplateHead
      title={"Funeral home with Free Funeral Advice | Fenix Funeral Directors"}
      description={
        "Arrange beautiful funeral from £1095. Funeral director with ✓ Top customer ratings 4.8 out of 5. ✓ Excellent Service ✓ Simple planning."
      }
      shortlink={"https://fenixfuneral.co.uk/locations/"}
      alternate={"https://fenixfuneral.co.uk/locations/"}
      canonical={"https://fenixfuneral.co.uk/locations/"}
      og={{
        url: "Funeral home with Free Funeral Advice | Fenix Funeral Directors",
        description:
          "Arrange beautiful funeral from £1095. Funeral director with ✓ Top customer ratings 4.8 out of 5. ✓ Excellent Service ✓ Simple planning.",
      }}
      ldjsonbreadcrumb={`{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
            "@type": "ListItem",
            "position": "1",
            "name": "Fenix Home","item": "https://fenixfuneral.co.uk/"
            },
            {
            "@type": "ListItem",
            "position": "2",
            "name": "Locations in UK","item": "https://fenixfuneral.co.uk/locations/"
            }
          ]
        }`}
    />
  );
}

export const pageQuery = graphql`
  fragment locationData on STRAPI_LOCATIONConnection {
    nodes {
      strapi_id
      title
      slug
      slugFull
      images {
        awsThumbnail
        awsImage
      }
      type {
        slug
        title
      }
      hub {
        title
        urlPart
      }
      city {
        title
        cityUrl
      }
    }
  }
  query {
    allLocations: allStrapiLocation(
      filter: {
        type: {
          slug: {
            in: [
              "churches"
              "cemeteries"
              "crematoriums"
              "woodland-burial-grounds"
            ]
          }
        }
      }
      sort: { fields: title, order: ASC }
    ) {
      ...locationData
    }
  }
`;
